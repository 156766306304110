import React, { useState, useEffect } from "react";
import { apiService } from "../services/apiService";
import { endpoints } from "../config/endpoints";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  CircularProgress,
  Alert,
} from "@mui/material";
import ChangePassword from "./ChangePassword";

const MyAccount: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const [open, setOpen] = useState(false);

  // Function to fetch user data by ID
  const fetchUserData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiService.get(endpoints.getUserById);
      setUser(response);
    } catch (err: any) {
      setError(err.response?.data?.message || "Failed to fetch user data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handlePasswordChange = (successMessage: string | null) => {
    setSuccess(successMessage);
    setTimeout(() => {
      setSuccess(null);
    }, 5000);
  };

  const handleChangePassword = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiService.post(endpoints.sendOtpOverMail, {email: user.email});
      setSuccess(response.message);
      setTimeout(() => {
        setSuccess(null);
      }, 5000);

      setOpen(true);
    } catch (err: any) {
      setError(err.response?.data?.message || "Failed to fetch user data.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        bgcolor: "#f5f5f5",
        p: 2,
      }}
    >
      <ChangePassword
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={handlePasswordChange}
        user={user}
        success={success}
      />
      <Paper
        elevation={3}
        sx={{
          width: 500,
          margin: "auto",
          mt: "2%",
          p: 4,
          borderRadius: 2,
          outline: "none",
          maxHeight: "60vh",
          overflowY: "auto",
        }}
      >
        <Typography variant="h5" align="center" gutterBottom>
          My Account
        </Typography>

        {loading && (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {success}
          </Alert>
        )}

        {user && (
          <Box
            component="form"
            display="flex"
            flexDirection="column"
            gap={2}
            mt={2}
          >
            <TextField
              label="First Name"
              variant="outlined"
              value={user.first_name}
              slotProps={{
                input: {
                  readOnly: true,
                },
              }}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              value={user.last_name}
              slotProps={{
                input: {
                  readOnly: true,
                },
              }}
            />
            <TextField
              label="Email"
              variant="outlined"
              value={user.email}
              slotProps={{
                input: {
                  readOnly: true,
                },
              }}
            />
            <TextField
              label="Phone"
              variant="outlined"
              value={user.phone}
              slotProps={{
                input: {
                  readOnly: true,
                },
              }}
            />
            <TextField
              label="roles"
              variant="outlined"
              value={user.roles}
              slotProps={{
                input: {
                  readOnly: true,
                },
              }}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleChangePassword}
            >
              Change Password
            </Button>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default MyAccount;
