export const endpoints = {
  login: "/login",
  logout: "/logout",
  groceries: "/groceries",
  addGroceries: "addGroceries",
  getGroceries: "/getGroceries",
  getStores: "/getStores",
  addStore: "/addStore",
  updateStore: "/updateStore",
  users: "/users",
  registerStoreUsers: "/registerStoreUsers",
  getAllUsers: "/getAllUsers",
  resetPasswordWithOtp: "/resetPasswordWithOtp",
  getUserById: "/getUserById",
  sendOtpOverMail: "/sendOtpOverMail"
};
