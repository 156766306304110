import React, { useState } from "react";
import { apiService } from "../services/apiService";
import { endpoints } from "../config/endpoints";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";

interface AddStoreProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const AddStore: React.FC<AddStoreProps> = ({ open, onClose, onSuccess }) => {
  const initialFormState = {
    name: "",
    address: "",
    pinCodes: [] as string[],
  };
  const [form, setForm] = useState(initialFormState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handlePinCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setForm({
      ...form,
      pinCodes: value.split(",").map((pin) => pin.trim()),
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    try {
      await apiService.post(endpoints.addStore, {
        name: form.name,
        address: form.address,
        pinCodes: form.pinCodes,
      });
      onSuccess();
      setForm(initialFormState);
      onClose();
    } catch (err: any) {
      console.error(err);
      setError(err.response?.data?.message || "Failed to add store");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="add-store-title">
      <Paper
        elevation={3}
        sx={{
          width: 500,
          margin: "auto",
          mt: "4%",
          p: 4,
          borderRadius: 2,
          outline: "none",
        }}
      >
        <Typography id="add-store-title" variant="h6" mb={2}>
          Add Store
        </Typography>
        {error && (
          <Typography color="error" mb={2}>
            {error}
          </Typography>
        )}
        <Box component="form" display="flex" flexDirection="column" gap={2}>
          <TextField
            label="Store Name"
            variant="outlined"
            name="name"
            value={form.name}
            onChange={handleInputChange}
          />
          <TextField
            label="Address"
            variant="outlined"
            name="address"
            value={form.address}
            onChange={handleInputChange}
          />
          <TextField
            label="Pin Codes (comma separated)"
            variant="outlined"
            name="pinCodes"
            value={form.pinCodes}
            onChange={handlePinCodeChange}
          />
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button onClick={onClose} color="error" disabled={loading}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default AddStore;
