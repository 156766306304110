import React, { useState, useEffect } from "react";
import { apiService } from "../services/apiService";
import { endpoints } from "../config/endpoints";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";

interface UpdateGroceriesProps {
  open: boolean;
  onClose: () => void;
  groceryData: any;
  onSuccess: () => void;
  storeID: string;
}

const UpdateGroceries: React.FC<UpdateGroceriesProps> = ({
  open,
  onClose,
  groceryData,
  onSuccess,
  storeID,
}) => {
  const [form, setForm] = useState({
    id: 0,
    name: "",
    barcode: "",
    description: "",
    category: "",
    quantity: 0,
    originalPrice: 0,
    discountedPrice: 0,
    imageUrl: "",
    weight: "",
    expirationDate: "",
    storeID,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (groceryData) {
      setForm({
        id: groceryData.groceryID,
        name: groceryData.name,
        barcode: groceryData.barcode,
        description: groceryData.description || "",
        category: groceryData.category || "",
        quantity: groceryData.quantity || 0,
        originalPrice: groceryData.originalPrice || 0,
        discountedPrice: groceryData.discountedPrice || 0,
        imageUrl: groceryData.imageUrl || "",
        weight: groceryData.weight || "",
        expirationDate: groceryData.expirationDate
          ? new Date(groceryData.expirationDate).toISOString().split("T")[0]
          : "",
        storeID: storeID,
      });
    }
  }, [groceryData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]:
        name === "quantity" ||
        name === "originalPrice" ||
        name === "discountedPrice"
          ? Number(value)
          : value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      await apiService.put(`${endpoints.groceries}/${form.id}`, form);
      onSuccess();
      onClose();
    } catch (err: any) {
      console.error(err);
      setError(err.response?.data?.message || "Failed to update grocery");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="update-grocery-title">
      <Paper
        elevation={3}
        sx={{
          width: 500,
          margin: "auto",
          mt: "2%",
          p: 4,
          borderRadius: 2,
          outline: "none",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <Typography id="update-grocery-title" variant="h6" mb={2}>
          Update Grocery
        </Typography>
        {error && (
          <Typography color="error" mb={2}>
            {error}
          </Typography>
        )}
        <Box component="form" display="flex" flexDirection="column" gap={2}>
          <TextField
            label="Name"
            variant="outlined"
            name="name"
            value={form.name}
            onChange={handleInputChange}
          />
          <TextField
            label="barcode"
            variant="outlined"
            name="barcode"
            value={form.barcode}
            onChange={handleInputChange}
          />
          <TextField
            label="Description"
            variant="outlined"
            name="description"
            value={form.description}
            onChange={handleInputChange}
          />
          <TextField
            label="Category"
            variant="outlined"
            name="category"
            value={form.category}
            onChange={handleInputChange}
          />
          <TextField
            label="Quantity"
            variant="outlined"
            type="number"
            name="quantity"
            value={form.quantity}
            onChange={handleInputChange}
          />
          <TextField
            label="Original Price"
            variant="outlined"
            type="number"
            name="originalPrice"
            value={form.originalPrice}
            onChange={handleInputChange}
          />
          <TextField
            label="Discounted Price"
            variant="outlined"
            type="number"
            name="discountedPrice"
            value={form.discountedPrice}
            onChange={handleInputChange}
          />
          <TextField
            label="Image URL"
            variant="outlined"
            name="imageUrl"
            value={form.imageUrl}
            onChange={handleInputChange}
          />
          <TextField
            label="Weight"
            variant="outlined"
            name="weight"
            value={form.weight}
            onChange={handleInputChange}
          />
          <TextField
            label="Expiration Date"
            variant="outlined"
            type="date"
            name="expirationDate"
            value={form.expirationDate}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button onClick={onClose} color="error" disabled={loading}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Update"
              )}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default UpdateGroceries;
