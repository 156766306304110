import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#282c34',
        color: 'white',
        padding: '10px 0',
        textAlign: 'center',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        mt: 'auto',
        left: 0,
        right: 0

      }}
    >
      <Typography variant="body2">
        &copy; 2024 React Admin App. All Rights Reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
