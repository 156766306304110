import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const getToken = () => localStorage.getItem("token");

const apiClient = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiService = {
  post: async (url: string, data: any) => {
    try {
      const response = await apiClient.post(url, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || "Something went wrong");
    }
  },

  get: async (url: string, params?: Record<string, any>) => {
    try {
      const response = await apiClient.get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        params, // Pass query parameters if provided
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || "Something went wrong");
    }
  },

  put: async (url: string, data: any) => {
    try {
      const response = await apiClient.put(url, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || "Something went wrong");
    }
  },

  patch: async (url: string, data: any) => {
    try {
      const response = await apiClient.patch(url, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || "Something went wrong");
    }
  },

  delete: async (url: string) => {
    try {
      const response = await apiClient.delete(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || "Something went wrong");
    }
  },
};
