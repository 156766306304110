import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import Groceries from '../groceries/Groceries';
import Users from '../users/Users';
import Stores from '../stores/Stores';

const TabPanel = ({ children, value, index }: { children: React.ReactNode; value: number; index: number }) => {
  return (
    <div hidden={value !== index}>
      {value === index && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const TabsExample = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      {/* Tabs Header */}
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Groceries" />
        <Tab label="Stores" />
        <Tab label="Users" />
      </Tabs>

      {/* Tab Panels */}
      <TabPanel value={currentTab} index={0}>
        <Groceries />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <Stores />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <Users />
      </TabPanel>
    </Box>
  );
};

export default TabsExample;
