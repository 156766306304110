import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../component/services/apiService";
import { endpoints } from "../component/config/endpoints";

export const fetchGroceries = createAsyncThunk(
  "groceries/fetchGroceries",
  async ({
    page,
    limit,
    category,
    name,
    storeID,
  }: {
    page: number;
    limit: number;
    category: string;
    name: string;
    storeID: string;
  }) => {
    const response = await apiService.get(endpoints.getGroceries, {
      page,
      limit,
      category,
      name,
      storeID,
    });
    return response;
  }
);

const groceriesSlice = createSlice({
  name: "groceries",
  initialState: {
    groceries: [],
    meta: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 0,
      limit: 10,
    },
    loading: false,
    error: null as string | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroceries.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGroceries.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { groceries, meta } = action.payload; // Assuming payload has both groceries and meta
        state.groceries = groceries || [];
        state.meta = meta || {
          totalItems: 0,
          totalPages: 0,
          currentPage: 0,
          limit: 10,
        };
      })
      .addCase(fetchGroceries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
        state.groceries = [];
        state.meta = {
          totalItems: 0,
          totalPages: 0,
          currentPage: 0,
          limit: 10,
        };
      });
  },
});

export default groceriesSlice.reducer;
