// Groceries.tsx
import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { fetchGroceries } from "../../store/groceriesSlice";
import AddGroceries from "./AddGroceries";
import UpdateGroceries from "./UpdateGroceries";
import { fetchStores } from "../../store/storesSlices";

import {
  Select,
  MenuItem,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
  Box,
  Container,
  Button,
  SelectChangeEvent,
} from "@mui/material";
import { AppDispatch } from "../../store/store"; // Import the AppDispatch type
import { useAuth } from "../../context/AuthContext";

// Define Grocery interface
interface Grocery {
  groceryID: number;
  name: string;
  description: string;
  category: string;
  quantity: number;
  barcode: string;
  originalPrice: number;
  discountedPrice?: number;
  weight: string;
  storeId: number;
  imageUrl: string;
  expirationDate?: string;
  createdBy?: number;
}

const Groceries = () => {
  const { role } = useAuth();
  const dispatch = useDispatch<AppDispatch>(); // Type the dispatch function

  // Get groceries and meta data from the Redux store
  const { groceries, loading, error, meta } = useSelector(
    (state: any) => state.groceries
  );

  // State for pagination and search
  const [page, setPage] = useState(0); // Set current page from meta (0-based index)
  const [rowsPerPage, setRowsPerPage] = useState(meta.limit || 10); // Set rows per page from meta
  const [searchTermCategory, setSearchTermCategory] = useState(""); // State to hold the search by category exact match
  const [searchByName, setSearchByName] = useState(""); // State to hold the search term
  const [openModal, setOpenModal] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [selectedGrocery, setSelectedGrocery] = useState<Grocery | null>(null);
  const [storeID, setStoreID] = useState<string>("");

  const { stores } = useSelector((state: any) => state.stores);

  useEffect(() => {
    dispatch(
      fetchStores({
        page: 1,
        limit: 10,
        name: "",
        types: "",
        pinCode: "",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    // Update storeID when stores array is updated and not empty
    if (stores?.length > 0) {
      setStoreID(stores[stores.length - 1]?.id); // Assuming `id` exists in store objects
    }
  }, [stores]);

  // Fetch groceries based on the current page, rows per page, and search term
  useEffect(() => {
    refreshGroceries();
  }, [dispatch, page, rowsPerPage, searchTermCategory, searchByName, storeID]);

  const refreshGroceries = () => {
    dispatch(
      fetchGroceries({
        page: page + 1,
        limit: rowsPerPage,
        category: searchTermCategory,
        name: searchByName,
        storeID: storeID,
      })
    ); // Pass search term to API
  };

  // Handle page change
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when changing rows per page
  };

  // Handle search input change
  const handleSearchByCategory = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTermCategory(event.target.value); // Update the search term
    setPage(0); // Reset to the first page on a new search
  };

  const handleNameSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchByName(event.target.value); // Update the search term
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenUpdateModal = (grocery: Grocery) => {
    setSelectedGrocery(grocery);
    setUpdateModalOpen(true);
  };

  const handleCloseUpdateModal = () => {
    setSelectedGrocery(null);
    setUpdateModalOpen(false);
  };

  const handleStoreChange = (event: SelectChangeEvent<any>) => {
    const selectedStoreId = event.target.value;
    setStoreID(selectedStoreId);
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: 2 }}>
      { error && <Typography color="error">{error}</Typography>}
      <AddGroceries
        open={openModal}
        onClose={handleCloseModal}
        onSuccess={refreshGroceries}
        storeID={storeID}
      />
      <UpdateGroceries
        open={updateModalOpen}
        onClose={handleCloseUpdateModal}
        groceryData={selectedGrocery}
        onSuccess={refreshGroceries}
        storeID={storeID}
      />
      <Paper
        elevation={3}
        sx={{ padding: 3, borderRadius: 2, backgroundColor: "#f5f5f5" }}
      >
        {/* Box container for Search and Pagination */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          {/* Search input */}
          <Box flex={1} mr={2}>
            <TextField
              label="Search By Category"
              variant="outlined"
              size="small"
              value={searchTermCategory}
              onChange={handleSearchByCategory}
              fullWidth
              sx={{
                backgroundColor: "#fff",
                borderRadius: 1,
              }}
            />
          </Box>
          <Box flex={1} mr={2}>
            <TextField
              label="Search By Name"
              variant="outlined"
              size="small"
              value={searchByName}
              onChange={handleNameSearch}
              fullWidth
              sx={{
                backgroundColor: "#fff",
                borderRadius: 1,
              }}
            />
          </Box>
          {stores.length > 0 && 
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                sx={{
                  textTransform: "none",
                  marginRight: 2,
                }}
              >
                Add Grocery
              </Button>
            </Box>
          }
          { role === 'Admin' && stores.length > 0 && 
            <Box>
              <Select
                labelId="store-select-label"
                value={storeID}
                onChange={handleStoreChange}
                displayEmpty
                variant="outlined"
                fullWidth
              >
                <MenuItem value="" disabled>
                  Select a store
                </MenuItem>
                {stores.map((store: any) => (
                  <MenuItem key={store.id} value={store.id}>
                    {store.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          }
          {/* Pagination */}
          <Box>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={meta.totalItems} // Use totalItems from meta
              rowsPerPage={rowsPerPage}
              page={page} // Material UI expects 0-based index for the page
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Box>

        {/* Table */}
        <TableContainer>
          <Table>
            <TableHead sx={{ backgroundColor: "#c2c7d1" }}>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Image</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Barcode</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Category</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Price</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Discounted Price</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Quantity</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Weight</Typography>
                </TableCell>
                { role === 'Admin' && stores.length > 0 && 
                  <TableCell align="center">
                    <Typography variant="h6">Actions</Typography>
                  </TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {groceries.map((grocery: Grocery) => (
                <TableRow
                  key={grocery.groceryID}
                  sx={{ "&:hover": { backgroundColor: "#f0f0f0" } }}
                >
                  <TableCell>{grocery.imageUrl}</TableCell>
                  <TableCell>{grocery.name}</TableCell>
                  <TableCell>{grocery.barcode}</TableCell>
                  <TableCell>{grocery.category}</TableCell>
                  <TableCell>{grocery.originalPrice}</TableCell>
                  <TableCell>{grocery.discountedPrice}</TableCell>
                  <TableCell>{grocery.quantity}</TableCell>
                  <TableCell>{grocery.weight}</TableCell>
                  { role === 'Admin' && stores.length > 0 && 
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() => handleOpenUpdateModal(grocery)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default Groceries;
