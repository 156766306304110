import React, { useState, useEffect } from "react";
import { apiService } from "../services/apiService";
import { endpoints } from "../config/endpoints";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";

interface AddGroceriesProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  storeID: string;
}

const AddGroceries: React.FC<AddGroceriesProps> = ({
  open,
  onClose,
  onSuccess,
  storeID,
}) => {
  const initialFormState = {
    name: "",
    barcode: "",
    description: "",
    category: "",
    quantity: 0,
    originalPrice: 0,
    discountedPrice: 0,
    weight: "",
    imageUrl: "",
    expirationDate: "",
    storeID,
  };
  const [form, setForm] = useState(initialFormState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setForm((prevForm) => ({
      ...prevForm,
      storeID,
    }));
  }, [storeID]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]:
        name === "quantity" ||
        name === "originalPrice" ||
        name === "discountedPrice"
          ? Number(value)
          : value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      await apiService.post(endpoints.addGroceries, form);
      onSuccess();
      setForm(initialFormState);
      onClose();
    } catch (err: any) {
      console.error(err);
      setError(err.response?.data?.message || "Failed to add grocery");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="add-grocery-title">
      <Paper
        elevation={3}
        sx={{
          width: 500,
          margin: "auto",
          mt: "2%",
          p: 4,
          borderRadius: 2,
          outline: "none",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <Typography id="add-grocery-title" variant="h6" mb={2}>
          Add Grocery
        </Typography>
        {error && (
          <Typography color="error" mb={2}>
            {error}
          </Typography>
        )}
        <Box component="form" display="flex" flexDirection="column" gap={2}>
          <TextField
            label="Name"
            variant="outlined"
            name="name"
            value={form.name}
            onChange={handleInputChange}
          />
          <TextField
            label="Barcode"
            variant="outlined"
            name="barcode"
            value={form.barcode}
            onChange={handleInputChange}
          />
          <TextField
            label="Description"
            variant="outlined"
            name="description"
            value={form.description}
            onChange={handleInputChange}
          />
          <TextField
            label="Category"
            variant="outlined"
            name="category"
            value={form.category}
            onChange={handleInputChange}
          />
          <TextField
            label="Quantity"
            variant="outlined"
            type="number"
            name="quantity"
            value={form.quantity}
            onChange={handleInputChange}
          />
          <TextField
            label="Original Price"
            variant="outlined"
            type="number"
            name="originalPrice"
            value={form.originalPrice}
            onChange={handleInputChange}
          />
          <TextField
            label="Discounted Price"
            variant="outlined"
            type="number"
            name="discountedPrice"
            value={form.discountedPrice}
            onChange={handleInputChange}
          />
          <TextField
            label="Image URL"
            variant="outlined"
            name="imageUrl"
            value={form.imageUrl}
            onChange={handleInputChange}
          />
          <TextField
            label="Weight"
            variant="outlined"
            name="weight"
            value={form.weight}
            onChange={handleInputChange}
          />
          <TextField
            label="Expiration Date"
            variant="outlined"
            type="date"
            name="expirationDate"
            value={form.expirationDate}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button onClick={onClose} color="error" disabled={loading}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default AddGroceries;
