import { configureStore } from '@reduxjs/toolkit';
import usersReducer from './usersSlice';
import groceriesReducer from './groceriesSlice';
import storeReducer from './storesSlices'
// import ordersReducer from './ordersSlice';

const store = configureStore({
  reducer: {
    users: usersReducer,
    groceries: groceriesReducer,
    stores: storeReducer,
    // orders: ordersReducer,
  },
});

export type AppDispatch = typeof store.dispatch;

export default store;
