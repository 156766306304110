import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../component/services/apiService";
import { endpoints } from "../component/config/endpoints";

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async ({
    page,
    limit,
    search,
  }: {
    page: number;
    limit: number;
    search: string;
  }) => {
    const response = await apiService.get(endpoints.getAllUsers, {
      page,
      limit,
      search,
    });
    return response;
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    meta: {
      totalUsers: 0,
      totalPages: 0,
      currentPage: 0,
      limit: 10,
    },
    loading: false,
    error: null as string | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        const { users, meta } = action.payload; // Assuming payload has both users and meta
        state.users = users || [];
        state.meta = meta || {
          totalUsers: 0,
          totalPages: 0,
          currentPage: 0,
          limit: 10,
        };
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
      });
  },
});

export default usersSlice.reducer;
