import React, { useState, useEffect } from "react";
import { apiService } from "../services/apiService";
import { endpoints } from "../config/endpoints";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";

interface UpdateStoreProps {
  open: boolean;
  onClose: () => void;
  storeData: any;
  onSuccess: () => void;
}

const UpdateStore: React.FC<UpdateStoreProps> = ({
  open,
  onClose,
  storeData,
  onSuccess,
}) => {
  const [form, setForm] = useState({
    id: 0,
    name: "",
    address: "",
    pinCodes: "" as string,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (storeData) {
      setForm({
        id: storeData.id,
        name: storeData.name || "",
        address: storeData.address || "",
        pinCodes: storeData.pinCodes?.join(", ") || "",
      });
    }
  }, [storeData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handlePinCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setForm({
      ...form,
      pinCodes: value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      const pinCodesArray = form.pinCodes.split(",").map((pin) => pin.trim());
      await apiService.put(`${endpoints.updateStore}/${form.id}`, {
        name: form.name,
        address: form.address,
        pinCodes: pinCodesArray,
      });
      onSuccess();
      onClose();
    } catch (err: any) {
      console.error(err);
      setError(err.response?.data?.message || "Failed to update store");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="update-store-title">
      <Paper
        elevation={3}
        sx={{
          width: 500,
          margin: "auto",
          mt: "2%",
          p: 4,
          borderRadius: 2,
          outline: "none",
        }}
      >
        <Typography id="update-store-title" variant="h6" mb={2}>
          Update Store
        </Typography>
        {error && (
          <Typography color="error" mb={2}>
            {error}
          </Typography>
        )}
        <Box component="form" display="flex" flexDirection="column" gap={2}>
          <TextField
            label="Store Name"
            variant="outlined"
            name="name"
            value={form.name}
            onChange={handleInputChange}
          />
          <TextField
            label="Address"
            variant="outlined"
            name="address"
            value={form.address}
            onChange={handleInputChange}
          />
          <TextField
            label="Pin Codes (comma separated)"
            variant="outlined"
            name="pinCodes"
            value={form.pinCodes}
            onChange={handlePinCodeChange}
          />
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button onClick={onClose} color="error" disabled={loading}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Update"
              )}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default UpdateStore;
