import React, { useEffect, useState } from "react";
import { useAuth } from '../../context/AuthContext';
import { useDispatch, useSelector } from "react-redux";
import { fetchStores } from "../../store/storesSlices"; // Ensure this action exists to fetch stores
import {
  Card,
  CardContent,
  Container,
  Paper,
  Box,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { AppDispatch } from "../../store/store";
import { TablePagination } from "@mui/material";
import UpdateStore from './UpdateStore';
import AddStore from './AddStore';

interface Store {
  id: number;
  name: string;
  address: string;
  types: string;
  pinCodes: [];
}

const Stores = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { role } = useAuth();
  // Fetch stores from Redux state
  const { stores, loading, error, meta } = useSelector(
    (state: any) => state.stores
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(meta.limit || 10);
  const [searchByName, setSearchByName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [selectedStore, setSelectedStore] = useState<any | null>(null);

  // Fetch stores based on page, rowsPerPage, and searchTerm
  useEffect(() => {
    refreshStores()
  }, [dispatch, page, rowsPerPage, searchByName]);

  const refreshStores = () => {
    dispatch(
      fetchStores({
        page: page + 1,
        limit: rowsPerPage,
        name: searchByName,
        types: "",
        pinCode: "",
      })
    ); // Pass search term for filtering
  }

  // Handle pagination change
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenUpdateModal = (store: Store) => {
    setSelectedStore(store);
    setOpenUpdateModal(true);
  };
  
  const handleCloseUpdateModal = () => {
    setSelectedStore(null);
    setOpenUpdateModal(false);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  // Handle search input change
  const handleNameSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchByName(event.target.value);
  };

  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container maxWidth="lg" sx={{ marginTop: 2 }}>
      { role === 'Admin' &&
        <Paper>
        <AddStore open={openModal} onClose={handleCloseModal} onSuccess={refreshStores}/>
        <UpdateStore
          open={openUpdateModal}
          onClose={handleCloseUpdateModal}
          storeData={selectedStore}
          onSuccess={refreshStores} // Callback to refresh the grocery list
        />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            {/* Search */}
            <Box flex={1} mr={2}>
              <TextField
                label="Search By Name"
                variant="outlined"
                size="small"
                value={searchByName}
                onChange={handleNameSearch}
                fullWidth
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: 1,
                }}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                sx={{
                  textTransform: 'none',
                  marginRight: 2,
                }}
              >
                Add Store
              </Button>
            </Box>

            {/* Pagination */}
            <Box>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={meta.totalItems}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Box>
          {/* Table */}
          <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: '#c2c7d1' }}>
                <TableRow>
                  <TableCell>Store Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Types</TableCell>
                  <TableCell>PinCode</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stores.map((store: Store) => (
                  <TableRow key={store.id}>
                    <TableCell>{store.name}</TableCell>
                    <TableCell>{store.address}</TableCell>
                    <TableCell>{store.types}</TableCell>
                    <TableCell>{store.pinCodes.join(', ')}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {handleOpenUpdateModal(store)}}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      }
      { role === 'storeAdmin' &&
        <Box 
          display="grid" 
          gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))" 
          gap={3}
        >
          {stores.map((store: Store) => (
            <Card key={store.id}>
              <CardContent>
                <Typography variant="h6">{store.name}</Typography>
                <Typography variant="body2" color="textSecondary">{store.address}</Typography>
                <Typography variant="body2">{store.types}</Typography>
                <Typography variant="body2">Pin Codes: {store.pinCodes.join(', ')}</Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      }
    </Container>
  );
};

export default Stores;
