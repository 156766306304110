import { Routes, Route } from "react-router-dom"; // Import routing components
import "./App.css"; // Import custom styles
import { useAuth } from "./context/AuthContext";
import Header from "../src/component/header/Header";
import Footer from "../src/component/footer/Footer";
import LoginPage from "../src/component/login/Login";
import Dashboard from "../src/component/dashboard/Dashboard";
import Users from "../src/component/users/Users";
import Groceries from "../src/component/groceries/Groceries";
import Stores from "../src/component/stores/Stores";
import MyAccount from "./component/users/MyAccount";

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <div className="app-container">
      {isAuthenticated && <Header />}

      <div className="main-content">
        <Routes>
          {!isAuthenticated ? (
            <>
              <Route path="/login" element={<LoginPage />} />
              <Route path="*" element={<LoginPage />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Dashboard />} />
              <Route path="/users" element={<Users />} />
              <Route path="/groceries" element={<Groceries />} />
              <Route path="/stores" element={<Stores />} />
              <Route path="/my_account" element={<MyAccount />} />
              <Route path="*" element={<Dashboard />} />
            </>
          )}
        </Routes>
      </div>

      {isAuthenticated && <Footer />}
    </div>
  );
};

export default App;
