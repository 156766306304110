import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Box } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { logout } = useAuth();
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget); // Opens the menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Closes the menu
  };

  const handleLogout = () => {
    logout();
    navigate('/login')
    handleMenuClose(); // Close the menu after logout
  };

  return (
    <AppBar position="sticky" color="primary">
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }} onClick={ () => {navigate('/dashboard')}}>
          Admin Dashboard App
        </Typography>
        
        <IconButton color="inherit" onClick={ () => {navigate('/my_account')}}>
          <AccountCircle />
        </IconButton>
        {/* Hamburger Icon */}
        <IconButton color="inherit" onClick={handleMenuClick}>
          <MenuIcon />
        </IconButton>

        {/* Menu for Links */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={ () => {navigate('/groceries'); handleMenuClose()}}>Grocery Inventory</MenuItem>
          <MenuItem onClick={ () => {navigate('/stores'); handleMenuClose()}}>Stores</MenuItem>
          <MenuItem onClick={ () => {navigate('/users'); handleMenuClose()}}>users</MenuItem>
          <MenuItem onClick={ () => {handleLogout()}}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
