import React, { useState, useEffect } from "react";
import { apiService } from "../services/apiService";
import { endpoints } from "../config/endpoints";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Paper,
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface ChangePasswordProps {
  user: any;
  success: string | null;
  open: boolean;
  onClose: () => void;
  onSubmit: (successMessage: string | null) => void;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({
  open,
  onClose,
  onSubmit,
  user,
  success,
}) => {
  const [form, setForm] = useState({
    email: "",
    otp: "",
    newPassword: "",
    verifyPassword: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);

  useEffect(() => {
    if (user) {
      setForm({
        email: user.email,
        otp: "",
        newPassword: "",
        verifyPassword: "",
      });
    }
  }, [user]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiService.post(endpoints.resetPasswordWithOtp, form);
      onSubmit(response.message)
      onClose();
    } catch (err: any) {
      console.error(err);
      setError(err.response?.data?.message || 'Failed to update Password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="change-password-title"
    >
      <Paper
        elevation={3}
        sx={{
          width: 400,
          margin: "auto",
          mt: "10%",
          p: 4,
          borderRadius: 2,
          outline: "none",
        }}
      >
        <Typography id="change-password-title" variant="h6" gutterBottom>
          Change Password
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {success}
          </Alert>
        )}

        <Box
          component="form"
          display="flex"
          flexDirection="column"
          gap={2}
          mt={2}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <TextField
            label="Email"
            variant="outlined"
            name="email"
            value={form.email}
            onChange={handleInputChange}
            slotProps={{
                input: {
                    readOnly: true,
                },
            }}
          />
          <TextField
            label="OTP"
            variant="outlined"
            name="otp"
            value={form.otp}
            onChange={handleInputChange}
            required
          />
          <TextField
            label="New Password"
            variant="outlined"
            name="newPassword"
            type={showPassword ? 'text' : 'password'}
            value={form.newPassword}
            onChange={handleInputChange}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword((prev) => !prev)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Verify Password"
            variant="outlined"
            name="verifyPassword"
            type={showVerifyPassword ? 'text' : 'password'}
            value={form.verifyPassword}
            onChange={handleInputChange}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowVerifyPassword((prev) => !prev)}
                    edge="end"
                  >
                    {showVerifyPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button onClick={onClose} color="error" disabled={loading}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Change"
              )}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default ChangePassword;
