import React, { createContext, useContext, useState, ReactNode } from "react";
import { apiService } from "../component/services/apiService";
import { endpoints } from "../component/config/endpoints";

interface AuthContextType {
  isAuthenticated: boolean;
  token: string | null;
  role: string | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("token") !== null
  );
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("token")
  );
  const [role, setRole] = useState<string | null>(localStorage.getItem("role"));

  const login = async (email: string, password: string) => {
    try {
      const response = await apiService.post(endpoints.login, {
        email,
        password,
      });
      const { token, user } = response; // Extract token from API response
      if (user.isAdmin || user.isStoreAdmin) {
        localStorage.setItem("token", token);
        localStorage.setItem("role", user.roles);
        setToken(token);
        setRole(user.roles);
        setIsAuthenticated(true);
      } else {
        throw new Error(
          "Permission denied. You do not have the necessary permissions."
        );
      }
    } catch (error) {
      throw new Error("Invalid credentials");
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    setToken(null);
    setRole(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, token, role, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
