import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from '../../store/usersSlice';
import { useAuth } from '../../context/AuthContext';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
  Box,
  Container,
  Button
} from '@mui/material';
import { AppDispatch } from '../../store/store';
import AddUser from './AddUser'

interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  roles: string,
  storeId: number,
  isAdmin: boolean;
  isStoreAdmin: boolean;
  createdAt: string;
  updatedAt: string;
}

const Users = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { role } = useAuth();
  const { users, loading, error, meta } = useSelector((state: any) => state.users);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(meta.limit || 10);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    refreshUsers()
  }, [dispatch, page, rowsPerPage, searchTerm]);

  const refreshUsers = () => {
    dispatch(fetchUsers({ page: page + 1, limit: rowsPerPage, search: searchTerm }));
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container maxWidth="lg" sx={{ marginTop: 4 }}>
      <AddUser open={openModal} onClose={handleCloseModal} onSuccess={refreshUsers}/>
      <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, backgroundColor: '#f5f5f5' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Box flex={1} mr={2}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
              fullWidth
              sx={{
                backgroundColor: '#fff',
                borderRadius: 1,
              }}
            />
          </Box>
          { role === 'Admin' &&
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                sx={{
                  textTransform: 'none',
                  marginRight: 2,
                }}
              >
                Add Store Users
              </Button>
            </Box>
          }
          <Box>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={meta.totalUsers}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Box>

        {/* Table */}
        <TableContainer>
          <Table>
            <TableHead sx={{ backgroundColor: '#c2c7d1' }}>
              <TableRow>
                <TableCell><Typography variant="h6">First Name</Typography></TableCell>
                <TableCell><Typography variant="h6">Last Name</Typography></TableCell>
                <TableCell><Typography variant="h6">Email</Typography></TableCell>
                <TableCell><Typography variant="h6">Phone</Typography></TableCell>
                <TableCell><Typography variant="h6">Roles</Typography></TableCell>
                <TableCell><Typography variant="h6">Store ID</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user: User) => (
                <TableRow key={user.id} sx={{ '&:hover': { backgroundColor: '#f0f0f0' } }}>
                  <TableCell>{user.first_name}</TableCell>
                  <TableCell>{user.last_name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.phone}</TableCell>
                  <TableCell>{user.roles}</TableCell>
                  <TableCell>{user.storeId && user.storeId.toString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default Users;
