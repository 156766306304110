import React, { useState, useEffect } from "react";
import { apiService } from "../services/apiService";
import { endpoints } from "../config/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { fetchStores } from "../../store/storesSlices";
import { AppDispatch } from "../../store/store";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Paper,
  CircularProgress,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

interface AddUserProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const AddUser: React.FC<AddUserProps> = ({ open, onClose, onSuccess }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { stores } = useSelector((state: any) => state.stores);

  useEffect(() => {
      dispatch(
        fetchStores({
          page: 1,
          limit: 10,
          name: "",
          types: "",
          pinCode: "",
        })
      );
  }, [dispatch]);

  const initialFormState = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    phone: "",
    storeId: "",
    roles: "",
  };

  const [form, setForm] = useState(initialFormState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const roles = [
    {
      id: 1,
      name: "storeAdmin",
    },
    {
      id: 2,
      name: "packers",
    },
    {
      id: 3,
      name: "movers",
    },
    {
      id: 4,
      name: "delivery",
    },
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("dd");
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleStoreChange = (event: SelectChangeEvent<string>) => {
    const selectedStoreId = event.target.value;
    setForm({
      ...form,
      storeId: selectedStoreId,
    });
  };

  const handleRolesChange = (event: SelectChangeEvent<string>) => {
    const selectedRolesName = event.target.value;
    setForm({
      ...form,
      roles: selectedRolesName,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      await apiService.post(endpoints.registerStoreUsers, form);
      setForm(initialFormState);
      onSuccess();
      onClose();
    } catch (err: any) {
      console.error(err);
      setError(err.response?.data?.message || "Failed to add user");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="add-user-title">
      <Paper
        elevation={3}
        sx={{
          width: 500,
          margin: "auto",
          mt: "2%",
          p: 4,
          borderRadius: 2,
          outline: "none",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <Typography id="add-user-title" variant="h6" mb={2}>
          Add User
        </Typography>
        {error && (
          <Typography color="error" mb={2}>
            {error}
          </Typography>
        )}
        <Box component="form" display="flex" flexDirection="column" gap={2}>
          <TextField
            label="First Name"
            variant="outlined"
            name="first_name"
            value={form.first_name}
            onChange={handleInputChange}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            name="last_name"
            value={form.last_name}
            onChange={handleInputChange}
          />
          <TextField
            label="Email"
            variant="outlined"
            name="email"
            value={form.email}
            onChange={handleInputChange}
          />
          <TextField
            label="Password"
            variant="outlined"
            name="password"
            type="password"
            value={form.password}
            onChange={handleInputChange}
          />
          <PhoneInput
            country={"in"}
            onlyCountries={["in"]}
            value={form.phone}
            onChange={(phone) => setForm({ ...form, phone })}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
            }}
            placeholder="Enter phone number"
            inputStyle={{
              width: "100%",
              borderRadius: "5px",
              border: "1px solid #ccc",
              padding: "0 50px",
            }}
          />
          <Select
            labelId="store-select-label"
            value={form.storeId}
            onChange={handleStoreChange}
            displayEmpty
            variant="outlined"
            fullWidth
          >
            <MenuItem value="" disabled>
              Select a store
            </MenuItem>
            {stores.map((store: any) => (
              <MenuItem key={store.id} value={store.id}>
                {store.name}
              </MenuItem>
            ))}
          </Select>
          <Select
            labelId="roles-select-label"
            value={form.roles}
            onChange={handleRolesChange}
            displayEmpty
            variant="outlined"
            fullWidth
          >
            <MenuItem value="" disabled>
              Select a Role
            </MenuItem>
            {roles.map((roles: any) => (
              <MenuItem key={roles.id} value={roles.name}>
                {roles.name}
              </MenuItem>
            ))}
          </Select>
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button onClick={onClose} color="error" disabled={loading}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default AddUser;
